// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/img/icons/globe-929292.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".language-dropdown{align-items:center;display:flex}.language-dropdown.icon:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;content:\"\";display:flex;height:1.25rem;margin-right:.5rem;width:1.25rem}.language-dropdown:hover{text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/components/LanguageDropdown.scss"],"names":[],"mappings":"AAEA,mBAEI,kBAAA,CADA,YACA,CAEI,+BAMI,wDAAA,CACA,uBAAA,CANA,UAAA,CAIA,YAAA,CAHA,cAAA,CAEA,kBAAA,CADA,aAIA,CAGR,yBACI,yBAAA","sourcesContent":["@import \"../variables\";\r\n\r\n.language-dropdown {\r\n    display: flex;\r\n    align-items: center;\r\n    &.icon {\r\n        &:before {\r\n            content: \"\";\r\n            height: 1.25rem;\r\n            width: 1.25rem;\r\n            margin-right: 0.5rem;\r\n            display: flex;\r\n            background-image: url(../assets/img/icons/globe-929292.svg);\r\n            background-size: contain;\r\n        }\r\n    }\r\n    &:hover {\r\n        text-decoration: underline;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
export var languageDropdown = "language-dropdown";
export var icon = "icon";
export default ___CSS_LOADER_EXPORT___;
