import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';

import {VIEW_DASHBOARD, VIEW_FLEET, VIEW_REPORT, VIEW_NOTIFICATION} from '../const';

import { useTranslation } from 'react-i18next';
import { Link, useParams, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie';
import './SiteDropdown.scss';

const SiteDropDownTitle = (props) => {
    const { t, i18n } = useTranslation('menu');

    const sites = props.sites;
    const siteId = props.siteId;
    
    if (siteId && sites && sites[0]) {
        var currentSite = sites.find(e => e.uuid === siteId);

        return (
            <div className='site-dropdown__title'>
                <svg width="16" height="25" viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8 10.5C6.896 10.5 6 9.604 6 8.5C6 7.396 6.896 6.5 8 6.5C9.104 6.5 10 7.396 10 8.5C10 9.604 9.104 10.5 8 10.5ZM8 5.5C6.343 5.5 5 6.843 5 8.5C5 10.157 6.343 11.5 8 11.5C9.657 11.5 11 10.157 11 8.5C11 6.843 9.657 5.5 8 5.5ZM1 8.102C1 4.585 4.271 1.5 8 1.5C11.729 1.5 15 4.585 15 8.102C15 11.557 12.437 15.645 8 22.629C3.511 15.556 1 11.557 1 8.102ZM8 0.5C3.802 0.5 0 3.903 0 8.102C0 12.3 3.469 17.312 8 24.5C12.531 17.312 16 12.3 16 8.102C16 3.903 12.199 0.5 8 0.5Z" fill="#929292"/>
                </svg>
                {/*<small>{currentSite.tenant.name}</small><br/>*/}
                {currentSite.name}
            </div>
        )
    }

    return (
        <div className='site-dropdown__title site-dropdown__title--select'>
            <svg width="16" height="25" viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 10.5C6.896 10.5 6 9.604 6 8.5C6 7.396 6.896 6.5 8 6.5C9.104 6.5 10 7.396 10 8.5C10 9.604 9.104 10.5 8 10.5ZM8 5.5C6.343 5.5 5 6.843 5 8.5C5 10.157 6.343 11.5 8 11.5C9.657 11.5 11 10.157 11 8.5C11 6.843 9.657 5.5 8 5.5ZM1 8.102C1 4.585 4.271 1.5 8 1.5C11.729 1.5 15 4.585 15 8.102C15 11.557 12.437 15.645 8 22.629C3.511 15.556 1 11.557 1 8.102ZM8 0.5C3.802 0.5 0 3.903 0 8.102C0 12.3 3.469 17.312 8 24.5C12.531 17.312 16 12.3 16 8.102C16 3.903 12.199 0.5 8 0.5Z" fill="#929292"/>
            </svg>
            {t('selectSite', { ns: 'menu' })}
        </div>
    )
}

const SiteDropdown = (props) => {
    const { t, i18n } = useTranslation('menu');
    const displayName = props.displayName;
    const firstLetter = props.displayName?.charAt(0);
    const expires = props.expires;

    const dispatch = useDispatch()

    const navigate = useNavigate();
    const params = useParams();
    const sites = useSelector((state) => state.sites.value)
    const [siteId, setSiteId] = useState('');
    const [idpTokenCookie, setIdpTokenCookie] = useCookies(['idpToken']);
    
    let tempTenantHeader = ''

    //let singleSiteReturnLabel = ""
    //let singleSiteReturnLink = "/"
    const [singleSiteReturnLabel, setSingleSiteReturnLabel] = useState('');
    const [singleSiteReturnLink, setSingleSiteReturnLink] = useState('');

    

    useEffect(() => {
        if (sites.length == 1) {
            if (sites[0].actions.indexOf(VIEW_DASHBOARD) !== -1) {
                setSingleSiteReturnLabel(t('dashboard', { ns: 'menu' }))
                setSingleSiteReturnLink("/site/"+sites[0].uuid+"/dashboard")
        
            } else if (sites[0].actions.indexOf(VIEW_FLEET) !== -1) {
                setSingleSiteReturnLabel(t('fleet', { ns: 'menu' }))
                setSingleSiteReturnLink("/site/"+sites[0].uuid+"/fleet")
        
            } else if (sites[0].actions.indexOf(VIEW_REPORT) !== -1) {
                setSingleSiteReturnLabel(t('reports', { ns: 'menu' }))
                setSingleSiteReturnLink("/site/"+sites[0].uuid+"/reports")

            }
        }
    }, [sites]);

    //t('dashboard', { ns: 'menu' })

    useEffect(() => {
        if(params.id){
            setSiteId(params.id)
        } else {
            setSiteId('')
        }
    }, [params]);

  
    return (
        <>
            {/*<NavLink to={singleSiteReturnLink} className='site-single' hidden={singleSiteReturnLink == '' || sites.length > 1 || location?.pathname == '/' || location?.pathname.indexOf("/site") !== -1}>
                {singleSiteReturnLabel}
                
    </NavLink>*/}
            <Dropdown className='site-dropdown' /*hidden={sites.length < 2}*/>
                <Dropdown.Toggle variant="outline-light" id="dropdown-basic" className='text-start site-dropdown__btn'>
                    <SiteDropDownTitle siteId={siteId} sites={sites} />
                </Dropdown.Toggle>
                
                <Dropdown.Menu variant="dark">
                    {sites.map((site, index)=>{
                        
                        let viewDashboard = false;
                        let viewFleet = false;
                        let viewReport = false;

                        viewDashboard = site.actions.indexOf(VIEW_DASHBOARD) !== -1 ? true : false;
                        viewFleet = site.actions.indexOf(VIEW_FLEET) !== -1 ? true : false;
                        viewReport = site.actions.indexOf(VIEW_REPORT) !== -1 ? true : false;
                        
                        if (tempTenantHeader != site.tenant.name) {
                            tempTenantHeader = site.tenant.name;

                            return (
                                <React.Fragment key={site.uuid}>
                                    <Dropdown.Header>{site.tenant.name}</Dropdown.Header>
                                    <Dropdown.Item key={site.uuid} 
                                        onClick={() => {
                                            if (viewDashboard) {
                                                navigate("/site/"+site.uuid+"/dashboard")
                                            } else if (viewFleet) {
                                                navigate("/site/"+site.uuid+"/fleet")
                                            } else if (viewReport) {
                                                navigate("/site/"+site.uuid+"/reports")
                                            } else {
                                                navigate("/site/"+site.uuid)
                                            }
                                        }}>
                                        {site.name}
                                    </Dropdown.Item>
                                </React.Fragment>
                            )
                        }

                        return (
                            <Dropdown.Item key={site.uuid} 
                                onClick={() => {
                                    if (viewDashboard) {
                                        navigate("/site/"+site.uuid+"/dashboard")
                                    } else if (viewFleet) {
                                        navigate("/site/"+site.uuid+"/fleet")
                                    } else if (viewReport) {
                                        navigate("/site/"+site.uuid+"/reports")
                                    } else {
                                        navigate("/site/"+site.uuid)
                                    }
                                }}>
                                {site.name}
                            </Dropdown.Item>
                        )
                        
                    })}
                
                </Dropdown.Menu>
                
            </Dropdown>
        </>
    )
}

export default SiteDropdown;