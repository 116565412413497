import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18next from '../i18n'

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams, NavLink, useNavigate } from 'react-router-dom';
import { setUserAlerts } from '../features/userAlertsSlice'

import { Scrollbar } from 'react-scrollbars-custom';
import Moment from 'react-moment';

import Loader from './Loader';
import ErrorInline from './ErrorInline';

import Dropdown from 'react-bootstrap/Dropdown';

import { setAdminToken } from '../features/adminTokenSlice'
import { setIdpToken } from '../features/idpTokenSlice'
import { useCookies } from 'react-cookie';


import {
    API_ADMIN_URI,
    API_IDP_URI,
    VIEW_NOTIFICATION,
    REFRESH_RATE
} from '../const';

import './UserAlertsDropdown.scss';


const UserAlertsDropdown = (props) => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('userAlertsDropdown')
    const idpToken = useSelector((state) => state.idpToken.value)
    const userAlerts = useSelector((state) => state.userAlerts.value)
    const sites = useSelector((state) => state.sites.value)
    const [isDataPending, setDataPending] = useState(true)
    const [isDataError, setDataError] = useState(false)
    
    const [idpTokenCookie, setIdpTokenCookie] = useCookies(['idpToken']);
    const [adminTokenCookie, setAdminTokenCookie] = useCookies(['adminToken']);

    
    
    const loadUserAlerts = () => {
        setDataError(false)
        setDataPending(true)
        const headers = {
            'Authorization': idpToken.token_type + ' ' + idpToken.access_token
        }
        axios.get(`${API_IDP_URI}v1/me/notifications/messages`, {
            headers: headers
        })
        .then(res => {
            let tempAlerts = []

            
            if(res.data.data){
                res.data.data.forEach(alert => {
                    if (alert.site.uuid) {
                        let temp = sites.find(site => site.uuid === alert.site.uuid)
                        
                        alert.site = temp
                    }
                    if (alert.site?.actions && alert.site.actions?.indexOf(VIEW_NOTIFICATION) !== -1) {
                        tempAlerts.push(alert)
                    }
                })
                let sortedAlerts = tempAlerts.sort((a, b) => b.created - a.created);
                dispatch(setUserAlerts(sortedAlerts))
                setDataPending(false)
            }
        }).catch((error) => {
            setDataError(true)
            setDataPending(false)

            if (error.response?.data?.error == 'invalid_access_token') {
                setAdminTokenCookie('adminToken', '', { path: '/' })
                setIdpTokenCookie('idpToken', '', { path: '/' })
                dispatch(setAdminToken({}));
                dispatch(setIdpToken({}));
                navigate("/login");
            }
        });
    }
    
    const AlertDropItem = (props) => {
        const alert = props.alert
        const classname = 'user-alerts-dropdown__item--' + (alert.criticality == null ? "high" : alert.criticality.toLowerCase() )
        
        return (
            <div className={'user-alerts-dropdown__item '+ classname} onClick={() => {
                navigate("/site/"+alert.site.uuid+"/fleet")
            }}>
                <div className='user-alerts-dropdown__item-crest'></div>
                <div className='user-alerts-dropdown__item-name'>
                    {alert.forklift.name}
                    <Moment date={alert.created} fromNow unix locale={i18next.language} />
                </div>
                <div className='user-alerts-dropdown__item-site'>{alert.site.name}</div>
                <div className='user-alerts-dropdown__item-tenant'>{alert.site.tenant.name}</div>
                <div className='user-alerts-dropdown__item-message'>{alert.message.content[i18next.language]}</div>
            </div>
        )
    }

    useEffect(() => {
        if (idpToken.access_token && sites.length > 0) {
            loadUserAlerts();
        }
        const interval = setInterval(() => {
            loadUserAlerts();
        }, REFRESH_RATE);

        return () => clearInterval(interval);
    }, [idpToken, sites])

    
    
    

    return (
        <div className='user-alerts-dropdown'>
            <Dropdown >
                <Dropdown.Toggle className='user-alerts-dropdown__btn'>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M20.75 17.0946V18.625H3.25V17.0946C3.43719 17.0336 3.61165 16.96 3.77449 16.875C5.51234 15.9677 5.92605 13.7544 6.35395 11.4651C6.8 9.07874 7.26147 6.60988 9.25425 5.45187C9.43463 5.34728 9.59285 5.21399 9.72461 5.05958C10.0051 4.73089 10.1657 4.30647 10.1651 3.85938V3.85675C10.1651 3.82092 10.1661 3.78533 10.1681 3.75C10.2228 2.77418 11.0222 2 12 2C12.9778 2 13.7772 2.77418 13.8311 3.75C13.8331 3.78533 13.834 3.82092 13.834 3.85675V3.85938C13.8334 4.30606 13.9946 4.73086 14.2753 5.05981C14.4069 5.21414 14.5649 5.34737 14.7449 5.45187C16.7382 6.60995 17.1998 9.07902 17.646 11.4655C18.074 13.7546 18.4878 15.9678 20.2255 16.875C20.3883 16.96 20.5628 17.0336 20.75 17.0946ZM16.9941 15.7492C17.1714 16.1372 17.3847 16.5179 17.6426 16.875H6.35745C6.60786 16.5305 6.83237 16.1377 7.05403 15.7463C7.74443 14.5521 8.0411 11.9634 8.0411 11.9634L8.06051 11.8597C8.29017 10.6333 8.485 9.59284 8.82668 8.70483C9.16304 7.83064 9.57609 7.28892 10.1334 6.96504L11.0531 6.19893L12.9467 6.19902L13.4064 6.58211L13.866 6.96519C14.4235 7.28915 14.8366 7.83092 15.173 8.70499C15.5148 9.5931 15.7097 10.6337 15.9395 11.8604L15.9588 11.9634C16.1799 13.1429 16.4458 14.5494 16.9941 15.7492ZM12 4.625C11.9441 4.625 11.8895 4.61979 11.8366 4.60984C11.4667 4.5399 11.1789 4.23681 11.1318 3.85938L11.1316 3.85809L11.1315 3.85675C11.1272 3.82176 11.125 3.78613 11.125 3.75C11.125 3.26787 11.517 2.875 12 2.875C12.483 2.875 12.875 3.26787 12.875 3.75C12.875 3.78585 12.8727 3.82202 12.8685 3.85675L12.8684 3.85809L12.8682 3.85938C12.8211 4.23705 12.5329 4.54028 12.1627 4.60997C12.11 4.61984 12.0556 4.625 12 4.625ZM10.0974 22.125C9.65593 21.6529 9.375 21.033 9.375 20.375H14.625C14.625 21.033 14.3553 21.6529 13.9258 22.125C13.4425 22.6561 12.7569 23 12.0254 23C11.2939 23 10.594 22.6561 10.0974 22.125Z" fill="white" fillOpacity="0.7"/>
                    </svg>
                    <div hidden={!userAlerts.length > 0} className='user-alerts-dropdown__btn-crest-warning'></div>
                    <div hidden={!userAlerts.length == 0} className='user-alerts-dropdown__btn-crest-ok'></div>
                </Dropdown.Toggle>

                <Dropdown.Menu align="end" className='user-alerts-dropdown__menu'>
                    <ErrorInline 
                        className='user-alerts-dropdown__error'
                        hidden={!isDataError}
                        onClick={() => loadUserAlerts()}
                    />
                    <div hidden={isDataError || isDataPending || userAlerts.length > 0} className='user-alerts-dropdown__empty' >
                        {t('noAlert', { ns: 'userAlertsDropdown' })}
                    </div>
                    <Scrollbar translateContentSizeYToHolder={true} disableTrackYWidthCompensation={true}>
                        {userAlerts.map((alert, index)=>{
                            return (<AlertDropItem alert={alert} key={alert.message.id} />)
                        })}
                    </Scrollbar>
                    <Loader hidden={!isDataPending || userAlerts.lenght > 0 || isDataError} className='user-alerts-dropdown__loader'/>
                    
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default UserAlertsDropdown;