import React from 'react';
import { useTranslation } from 'react-i18next';

import './LanguageDropdown.scss';

const LanguageDropDown = (props) => {
  const { t, i18n } = useTranslation(['menu', 'common']);
  const className = props.className;
  let hidden = props.hidden;

  if(props.hidden == undefined) {
    hidden = false
  }


  const changeLanguage = (lng, event) => {
    event.preventDefault();
    //event.stopPropagation();
    i18n.changeLanguage(lng);
  };

  return (
    <div className={"dropdown language-dropdown "+className} hidden={hidden}> 
        <a href="#" className="text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> 
          {t('currentLanguageText', { ns: 'common' })} 
        </a> 
        <ul className="dropdown-menu dropdown-menu-dark text-small shadow"> 
            <li><a href="#" className="dropdown-item" disabled={i18n.language == 'fr' ? true : false} onClick={(e) => changeLanguage('fr', e)}>{t('frenchText', { ns: 'common' })}</a></li> 
            <li><a href="#" className="dropdown-item" disabled={i18n.language == 'fr' ? true : false} onClick={(e) => changeLanguage('en', e)}>{t('englishText', { ns: 'common' })}</a></li> 
        </ul> 
    </div> 
      
  );
};

export default LanguageDropDown;